/* eslint-disable no-case-declarations */
import { throttle } from 'public/src/js/utils/event'
import { toDecimal } from 'public/src/js/utils/math'

var _window = typeof window === 'undefined' ? global : window

export default class expose {
  /**
   * 构造函数
   *
   * 滚动容器
   * @param {nodeList | String} scrollContainer 滚动容器
   * @param {Number} scrollTopMask 滚动容器顶部遮挡高度
   * @param {Number} scrollBottomMask 滚动容器底部遮挡高度
   *
   * 列表容器，列表内容
   * @param {String} section
   * @param {String} section.container 列表容器类名 ex：j-expose__container-list
   * @param {String} section.content 列表内容类名 ex：j-expose__content-goods
   * @param {String} section.target 曝光模块类名 ex：j-expose__target-goods-img
   *
   * 滚动事件参数
   * @param {Number} interval 滚动事件节流时长，单位：毫秒
   * @param {Number} wait 滚动事件停止多久后再次触发事件回调，单位：毫秒
   *
   * 曝光调教配置参数
   *  @param {Number} targetExposeRadio 曝光比例，曝光块列表在视口的面积比例
   *  @param {Number} targetExposeTime 曝光时间，单位：毫秒
   *  @param {Function} callback 曝光回调
   */
  constructor({
    scrollContainer = null,
    scrollTopMask = 0,
    scrollBottomMask = 0,
    section = [],
    interval = 1000,
    wait = null,
    callback = null,
    targetExposeRadio = 0,
    targetExposeTime = 1000
  } = {}) {
    try {
      if (typeof callback !== 'function') throw 'Parameter illegal'

      //scroll
      this.initScrollEventConfig({ interval, wait })
      this.initScroll({ scrollContainer, scrollTopMask, scrollBottomMask })

      //expose config
      this.initExposeConfig({ targetExposeRadio, targetExposeTime, callback })

      //get container rect info
      this.initListInfo({ section })

      // bind event
      this.setScrollEvent()
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * 初始化滚动容器事件配置
   * @param {Number} interval 滚动事件截流间隔时间，单位：毫秒
   * @param {Number} wait 滚动事件停止多久后再次触发事件回调，单位：毫秒
   */
  initScrollEventConfig({ interval, wait } = {}) {
    this.interval = interval
    this.wait = wait
  }

  /**
   * 初始化滚动容器基本信息
   * @param {nodeList | String} scrollContainer 滚动容器
   * @param {Number} scrollTopMask 滚动容器顶部遮挡高度
   * @param {Number} scrollBottomMask 滚动容器底部遮挡高度
   */
  initScroll({ scrollContainer, scrollTopMask, scrollBottomMask } = {}) {
    // default value
    let info = {
      event: null,
      dom: null,
      height: null,
      width: 0,
      topMask: 0,
      position: { top: 0, bottom: 0, left: 0, right: 0 }
    }

    info.dom = $(scrollContainer)
    info.event = throttle.call(this, {
      fun: this.exposeHandler,
      interval: this.interval,
      wait: this.wait
    })
    info.topMask = scrollTopMask
    info.bottomMask = scrollBottomMask
    this.scrollContainer = info

    if (info.dom.length <= 0) throw new Error('scroll container no fount')

    if (info.dom[0] === window) {
      info.height = info.dom.height()
      info.width = info.dom.width()

      // 去掉遮挡物视口高度
      info.viewAreaHeight = info.height - info.bottomMask - info.topMask
    } else {
      info.height = info.dom.height()
      info.width = info.dom.width()

      // 去掉遮挡物视口高度
      info.viewAreaHeight = info.height - info.bottomMask - info.topMask

      // 获取scroll container的位置信息
      let rect = info.dom[0].getBoundingClientRect()
      info.position.top = rect.top
      info.position.bottom = rect.bottom
      info.position.left = rect.left
      info.position.right = rect.right
    }
  }

  /**
   *  初始化曝光配置
   *  @param {Number} targetExposeRadio 曝光比例
   *  @param {Number} targetExposeTime 曝光时间，单位：毫秒
   *  @param {Function} callback 曝光回调
   */
  initExposeConfig({ targetExposeRadio, targetExposeTime, callback } = {}) {
    this.targetExposeRadio = targetExposeRadio
    this.targetExposeTime = targetExposeTime
    this.callback = callback
    this.targetExposeNum = Math.ceil(this.targetExposeTime / this.interval)
    this.exposeList = {}
    this.HasBeenExpose = {}
  }

  /**
   * 初始化列表容器，列表内容, 曝光模块
   * 获取dom，计算dom位置信息
   * 重置section container，content，target
   * @param {Array} section 列表容器 list
   * @param {String} section.container 列表容器类名 ex：j-expose__container-list
   * @param {String} section.content 列表内容类名 ex：j-expose__content-goods
   * @param {String} section.target 曝光模块类名 ex：j-expose__target-goods-img
   *
   * className 类名
   * dom node
   * rect 位置信息
   * rowNum 容器中每行内容个数
   * maxRowNum 最大行数
   * lastRowContentNum 最后一行内容个数
   */
  initListInfo({ section = [] } = {}) {
    this.section = section
    section.forEach(item => {
      // default value
      let container = {
        className: item.container,
        dom: null,
        rect: null,
        rowNum: 0,
        maxRowNum: 0,
        lastRowContentNum: 0
      }
      let content = { className: item.content, dom: null, rect: null }
      let target = { className: item.target }

      item.container = container
      item.content = content
      item.target = target

      this.getListDomInfo(item)
      this.getListItemRectInfo(item)
    })
  }
  /**
   * 获取列表容器，列表内容 nodeList
   */
  getListDomInfo({ container, content } = {}) {
    //get container content dom
    let containerDom = document.getElementsByClassName(container.className)
    if (containerDom.length <= 0) return
    container.dom = containerDom[0]

    let contentDom = container.dom.getElementsByClassName(content.className)
    if (contentDom.length <= 0) return
    content.dom = contentDom
  }

  /**
   *  获取列表容器，列表内容 大小和位置信息
   *  计算最大行数，每行最大排列数，最后一行排列数
   */
  getListItemRectInfo({ container, content } = {}) {
    if (!container.dom || container.dom.length <= 0 || !content.dom || content.dom.length <= 0)
      return
    container.rect = this.getBoundingClientRectRelativeScroll({
      dom: container.dom,
      type: 'container'
    })
    content.rect = this.getBoundingClientRectRelativeScroll({
      dom: content.dom[0],
      type: 'content'
    })

    let rowNum = 0
    let lastRowNum = 0
    // get per row content num
    container['rowNum'] = rowNum = Math.floor(container.rect.width / content.rect.width)
    container['maxRowNum'] = Math.ceil(content.dom.length / rowNum)

    // get last row num
    lastRowNum = content.dom.length % rowNum
    container['lastRowContentNum'] = lastRowNum === 0 ? rowNum : lastRowNum
  }

  /**
   *  遍历列表容器list，获取列表容器，列表内容 大小和位置信息
   *  计算最大行数，每行最大排列数，最后一行排列数
   */
  getListRectInfo() {
    this.section.forEach(item => {
      this.getListDomInfo(item)
      this.getListItemRectInfo(item)
    })
  }

  /**
   * 监听事件滚动容器
   */
  setScrollEvent() {
    let scroll = this.scrollContainer
    scroll.dom.on('scroll', scroll.event)
    scroll.dom.trigger('scroll')
  }

  /**
   * 获取dom位置信息，相对于滚动容器 / 遮挡物 的位置做修正
   */
  getBoundingClientRectRelativeScroll({ dom = null, type = 'container' } = {}) {
    if (!dom || !type) return {}
    let rect = dom.getBoundingClientRect()
    let returnRect = { top: 0, bottom: 0, height: 0, width: 0, left: 0, right: 0, x: 0, y: 0 }
    let { position: scrollPosition, topMask } = this.scrollContainer
    switch (type) {
      case 'container':
        // 相对于滚动容器
        returnRect.top = rect.top - scrollPosition.top - topMask
        returnRect.bottom = rect.bottom - scrollPosition.top - topMask
        returnRect.y = rect.y - scrollPosition.top - topMask
        break
      case 'content':
      case 'target':
        // 滚动容器 / 遮挡物
        returnRect.top = rect.top - scrollPosition.top - topMask
        returnRect.bottom = rect.bottom - scrollPosition.top - topMask
        returnRect.y = rect.y - scrollPosition.top - topMask
        break
    }
    return {
      top: returnRect.top,
      bottom: returnRect.bottom,
      height: returnRect.height || rect.height,
      width: returnRect.width || rect.width,
      left: returnRect.left || rect.left,
      right: returnRect.right || rect.right,
      x: returnRect.x || rect.x,
      y: returnRect.y
    }
  }

  /**
   * 滚动事件回调
   * 检验是否在屏幕视口，是否满足曝光条件，计算曝光列表
   * @param {Boolean} isWait 滚动事件停止后 wait秒后回调触发
   */
  exposeHandler({ isWait = false } = {}) {
    // console.time('exposeEventTime')

    let exposeGoods = {}
    this.section.forEach(({ code, container, content, target, averageCotent }) => {
      // check content dom
      if (!container.dom || !content.dom || !content.rect.height) {
        this.getListDomInfo({ container, content })
        this.getListItemRectInfo({ container, content })
        if (!container.dom || !content.dom) return
      }

      // check in screen
      let inScreen = this.checkContainerInScreen({ container })
      // console.log(code + ' inScreen : ' + inScreen)
      if (!inScreen) return (this.exposeList[code] = {})

      // check is expose
      let { beginRow, endRow } = this.getExposeLine({ container, content, averageCotent })

      /**
       *  get expose content list per interval
       */
      let list = this.getContentListByRow({ container, content, beginRow, endRow })

      /**
       *  compute expose item
       */
      let has = Object.prototype.hasOwnProperty
      let intersection = {}
      let difference = {}
      let hasExpose = []

      !has.call(this.exposeList, code) && (this.exposeList[code] = {})

      list.forEach(contentItem => {
        let id = contentItem.getAttribute('data-expose-id')
        id = `${id}_${code}`
        // check has been expose
        if (!!has.call(this.HasBeenExpose, id)) return

        // check is expose
        let targetExposeInfo = this.computedTargetExpose({ content: contentItem, target })
        if (!targetExposeInfo.expose) return

        // check is wait event callback
        // compute intersection,difference
        let num = 0
        num = !isWait
          ? !!has.call(this.exposeList[code], id)
            ? (intersection[id] = ++this.exposeList[code][id])
            : (difference[id] = 1)
          : this.targetExposeNum

        let targetDom = targetExposeInfo.targetDom
        // check is greater than expose num
        num >= this.targetExposeNum &&
          (hasExpose.push({ content: contentItem, target: targetDom }),
          (this.HasBeenExpose[id] = { content: contentItem, target: targetDom }))
      })
      this.exposeList[code] = Object.assign({}, intersection, difference)
      hasExpose.length > 0 && (exposeGoods[code] = hasExpose)
    })
    this.callback({ list: exposeGoods })

    // console.timeEnd('exposeEventTime')
  }

  /**
   * 检验列表容器是否在视口中
   *  考虑顶部有上下遮挡物的情况
   */
  checkContainerInScreen({ container } = {}) {
    let rect = (container.rect = this.getBoundingClientRectRelativeScroll({
      dom: container.dom,
      type: 'container'
    }))
    let { viewAreaHeight } = this.scrollContainer
    return rect.top < viewAreaHeight && rect.bottom > 0
  }

  /**
   * 获取曝光行数
   */
  getExposeLine({ container, content, averageCotent }) {
    let { viewAreaHeight } = this.scrollContainer
    let containerRect = container.rect
    let exposeInfo = null

    this.exposeInfo = exposeInfo =
      containerRect.top > 0
        ? { type: 'head', area: viewAreaHeight - containerRect.top }
        : containerRect.bottom < viewAreaHeight
          ? { type: 'foot', area: containerRect.bottom }
          : { type: 'full', area: viewAreaHeight }

    let { beginRow = 0, endRow = 0 } = !averageCotent
      ? this.getExposeLineByBinarySearch({ container, content, exposeInfo })
      : this.getExposeLineByRatio({ container, content, exposeInfo })

    // console.log(`beginRow: ${beginRow}; endRow: ${endRow}; exposeInfo: ${JSON.stringify(exposeInfo)}`)

    return {
      exposeInfo,
      beginRow,
      endRow
    }
  }

  /**
   * 获取曝光行数，内容框高度不均等，通过二分法计算
   *  考虑顶部有遮挡物的情况
   *  @returns {Object}
   *  isExpose 满足曝光条件
   *  beginRow 曝光区间，第几行开始
   *  endRow 曝光区间，第几行结束
   *  exposeInfo
   */
  getExposeLineByBinarySearch({ container, content, exposeInfo }) {
    let { viewAreaHeight } = this.scrollContainer
    // get first content per row
    let list = [...content.dom].filter((item, index) => index % container.rowNum === 0)
    _window.templateList = list

    let beginRow = 0
    let endRow = 0
    let endRowIndex

    switch (exposeInfo.type) {
      case 'head':
        endRowIndex = list.findIndex(
          item =>
            this.getBoundingClientRectRelativeScroll({ dom: item, type: 'content' }).bottom >=
            viewAreaHeight
        )
        beginRow = 1
        // 【fix】最后一行距离底部的距离小于容器高度，findIndex为 -1，说明曝光目标都在视口中
        endRow = endRowIndex < 0 ? list.length : endRowIndex + 1
        break
      case 'foot':
        beginRow = list
          .reverse()
          .findIndex(
            item => this.getBoundingClientRectRelativeScroll({ dom: item, type: 'content' }).top < 0
          )
        endRow = container.maxRowNum
        // 【fix】第一行距离顶部的距离大于顶部遮挡高度(topMask)，findIndex为 -1，说明曝光目标都在视口中
        beginRow = beginRow < 0 ? 1 : list.length - beginRow
        break
      case 'full':
        beginRow = this.binarySearch({ type: 'begin', list }) + 1
        endRow = this.binarySearch({ type: 'end', list }) + 1
        break
      default:
        break
    }

    return {
      beginRow,
      endRow
    }
  }

  binarySearch({ type = 'begin', list, start = 0, end = list.length }) {
    let mid = parseInt(start + (end - start) / 2)
    // 边界
    if (mid === start) return mid
    let rect = this.getBoundingClientRectRelativeScroll({ dom: list && list[mid], type: 'content' })

    switch (type) {
      case 'begin':
        if (rect.top > 0) return this.binarySearch({ type, list, start, end: mid })
        if (rect.bottom <= 0) return this.binarySearch({ type, list, start: mid, end })
        return mid
      case 'end':
        let { viewAreaHeight } = this.scrollContainer
        if (rect.top >= viewAreaHeight) return this.binarySearch({ type, list, start, end: mid })
        if (rect.bottom < viewAreaHeight) return this.binarySearch({ type, list, start: mid, end })
        return mid
    }
  }

  /**
   * 获取曝光行数，内容框高度均等，通过计算比例获取
   *  考虑顶部有遮挡物的情况
   */
  getExposeLineByRatio({ container, content, exposeInfo } = {}) {
    let containerRect = container.rect
    let contentRect = content.rect
    let beginRow = 0
    let endRow = 0
    let ratio = toDecimal(exposeInfo.area / contentRect.height, 2)
    switch (exposeInfo.type) {
      case 'head':
        beginRow = 1
        endRow = Math.ceil(ratio)
        break
      case 'foot':
      case 'full':
        /**
         * compute begin row
         */
        let overflow = 0
        let overflowArea = 0
        let oneRowRemind = 0
        // compute overflow ratio
        overflowArea = Math.abs(containerRect.top)
        overflow = toDecimal(overflowArea / contentRect.height, 2)

        // compute in screen the first row remind
        oneRowRemind = 1 - Number(overflow.split('.')[1] / 100)
        beginRow = Math.ceil(overflow)

        /**
         * compute end row
         */
        let remind = 0
        remind = toDecimal(ratio - oneRowRemind, 2)
        endRow = beginRow + Math.ceil(remind)
        break
    }

    return {
      beginRow,
      endRow
    }
  }

  /**
   * 获取内容列表区间范围，通过开始和结束行数
   * @param {Number} beginRow 曝光区间，第几行开始
   * @param {Number} endRow 曝光区间，第几行结束
   * @returns {Array}
   */
  getContentListByRow({ container, content, beginRow = 1, endRow = 1 } = {}) {
    let line = endRow - beginRow + 1
    let { rowNum, maxRowNum, lastRowContentNum } = container
    let num = endRow !== maxRowNum ? line * rowNum : (line - 1) * rowNum + lastRowContentNum
    return [...content.dom].slice((beginRow - 1) * rowNum, (beginRow - 1) * rowNum + num)
  }

  /**
   * 计算目标是否曝光
   * @param {nodeList} content 列表内容dom
   * @returns {Boolean}
   */
  computedTargetExpose({ content, target } = {}) {
    let { viewAreaHeight } = this.scrollContainer
    let targetDom = content.getElementsByClassName(target.className)[0]
    let rect = null
    let exposeArea = 0
    let exposeRatio = 0

    if (!targetDom) return { expose: false }
    rect = this.getBoundingClientRectRelativeScroll({ dom: targetDom, type: 'target' })

    // item高度不高于视口范围内的高度
    exposeArea =
      rect.top <= 0
        ? rect.bottom
        : rect.bottom > viewAreaHeight
          ? viewAreaHeight - rect.top
          : rect.height

    // 不在视口内
    if (exposeArea <= 0) return { expose: false }

    exposeRatio = exposeArea / rect.height
    if (exposeRatio < this.targetExposeRadio) return { expose: false }

    return {
      expose: true,
      targetDom
    }
  }

  /** 移除事件监听 */
  removeScrollEvent() {
    let scroll = this.scrollContainer
    scroll.dom.off('scroll', scroll.event)
    this.section = []
    this.HasBeenExpose = null
  }
}
